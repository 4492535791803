.Select {
  padding: 7px;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  border-radius: 8px;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #6B4FFB;
  cursor: pointer;

  &.primary {
    width: 285px;
  }
  
  &.secondary {
    width: 55px;
  }

  .option-select {
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: #6B4FFB;
    // overflow: scroll;

    &:hover {
      color: #1E1E1E;
      background: rgba(0, 0, 0, 0.2);
    }
  }

  &:hover {
    border: 1px solid #6B4FFB;
  } 
}