.Questionarie {
  margin: 318px auto 0;
  padding: 25px;
  width: 335px;
  height: 326px;
  background: #FFFFFF;
  opacity: 0.9;
  border: 1px solid #6B4FFB;
  box-sizing: border-box;
  border-radius: 8px;

  &-title {
    margin-bottom: 25px;
    font-weight: normal;
    font-size: 24px;
    line-height: 26px;
    text-align: center;
    color: #6B4FFB;
  }

  &-text {
    margin: 15px 0 20px 0;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #1E1E1E;
  }

  

  .secondary-select {
    text-align: center;

    span {
      color: rgba(0, 0, 0, 0.2);
    }
  }

  &-btn {
    margin-top: 25px;
  }
}

@media (max-width: 580px) {
  .Questionarie {
    margin-top: 101px;
  }
}

@media (max-width: 480px) {
  .Main {
    .main-bg {
      height: 480px;
    }
    .container {
      max-width: 340px;
    }
  }
}