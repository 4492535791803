.Header {
  width: 100%;
  height: 84px;
  background: #FFFFFF;

  .header-content{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .header-logo {
    display: flex;
    align-items: center;
    img {
      &:not(:first-of-type) {
        margin-left: 7px;
      }
    }
  }

  .mobile-menu {
    display: none;
  }
}

/* Media queries */
@media (max-width: 1280px) {
  .container {
    max-width: 992px;
  }
}

@media (max-width: 992px) {
  .container {
    max-width: 768px;
  }
}

@media (max-width: 768px) {
  .Header {
    .container {
      max-width: 640px;
    }
  
    .header-btn {
      display: none;
    }
  
    .mobile-menu {
      display: block;
      cursor: pointer;
    }
  }
}

@media (max-width: 580px) {

  .Header {
    .container {
      max-width: 480px;
    }
  }
}

@media (max-width: 480px) {

  .Header {
    .container {
      max-width: 340px;
    }
  }
}