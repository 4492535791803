.About {
  width: 100%;

  .about-bg {
    width: 100%;
    height: 639px;
    background: center no-repeat;
  }

  .about-content {
    margin: 60px auto;
    padding: 0 10%;
    
    .about-title {
      margin-bottom: 25px;
      font-weight: 600;
      font-size: 28px;
      line-height: 37px;
      color: #1E1E1E;
    }

    .about-text {
      margin-bottom: 12px;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      color: #1E1E1E;

      &:last-of-type {
        margin-bottom: 40px;
      }
    }

    .about-img {
      margin: 0 0 30px;
      text-align: center;
      img {
        width: 100%;
        height: 510px;
      }
    }

    .about-finally {
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      color: #1E1E1E;
    }
  }
}

@media (max-width: 992px) {
  .About {
    
    .container {
      max-width: 768px;
    }
  }
}

@media (max-width: 768px) {
  .About {
    .container {
      max-width: 640px;

      .about-content {
        margin: 50px 0;
        padding: 0;
        
        .about-img {
          img {
            height: 380px;
          }
        }
      }
    }
  }
}

@media (max-width: 580px) {
  .About {

    .about-bg {
      width: 100%;
      height: 288px;
    }

    .container {
      max-width: 480px;

      .about-content {
        
        .about-img {
          img {
            width: 100%;
            height: 146px;
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .About {
    .container {
      max-width: 340px;
    }
  }
}