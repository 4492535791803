body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: 'Segoe UI', sans-serif;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  color: #fff;
}

body {
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: -0.025em;
  color: #fff;
}

button, select {
  &:focus {
      outline: none;
  }
}

a {
  text-decoration: none;
}


.container {
  display: flex;
  width: 100%;
  height: 100%;
  max-width: 1180px;
  margin: 0 auto;
}

.direction-column {
  flex-direction: column;
}
