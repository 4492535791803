.Button {
  background: #6B4FFB;
  border: none;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: #FFF;
  cursor: pointer;
  text-align: center;

  &.header-btn {
    padding: 6px 0;
    height: 30px;
    width: 199px;
    border-radius: 15px;
    font-weight: 600;
  }

  &.select-btn {
    padding: 10.5px 0 11.5px;
    height: 40px;
    width: 285px;
    border-radius: 20px;
  }

  &:hover {
    background: #FC7C1E;
  }
}