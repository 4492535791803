.Footer {
  height: 110px;
  width: 100%;
  background: #6B4FFB;

  .content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .navigation {
      width: 215px;
      display: flex;
      justify-content: space-between;

      a {
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
      }
    }

    .privacy {
      font-weight: normal;
      font-size: 11px;
      line-height: 15px;

      a {
        color: #FC7C1E;
      }
    }
  }
}

@media (max-width: 1280px) {
  .container {
    max-width: 992px;
  }
}

@media (max-width: 992px) {
  .container {
    max-width: 768px;
  }
}

@media (max-width: 768px) {
  .Footer {
    height: 190px;
    .container {
      max-width: 640px;
  
      .content {
        padding: 10px 0 20px;
        flex-direction: column;
        align-items: flex-start;
  
        .navigation {
          flex-direction: column;
          height: 100px;

          a {
            text-transform: uppercase;
          }
        }
      }
    }
  }
}

@media (max-width: 580px) {
  .Footer {
    .container {
      max-width: 480px;
    }
  }
}

@media (max-width: 480px) {
  .Footer {
    .container {
      max-width: 340px;
    }
  }
}