.Drawer {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  max-width: 400px;
  padding: 20px 10px;
  box-sizing: border-box;
  background: #6B4FFB;
  animation: anim-open .5s ease !important;
  z-index: 90;

  &.close {
    animation: anim-hidden .5s ease !important;
  }

  &_close {
    position: absolute;
    right: 21px;
    top: 21px;
    cursor: pointer;
  }

  &_btn-install {
    margin: 80px 0 30px;
    font-weight: normal;
    font-size: 24px;
    line-height: 26px;
    text-align: center;
    cursor: pointer;
    user-select: none;
    &:hover {
      opacity: .7;
    }
  }

  &_line {
    display: flex;
    justify-content: center;
    span {
      display: inline-block;
      width: 320px;
      height: 1px;
      background: #FFFFFF;
    }
  }

  & ul {
    height: 80%;
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;

    li {
      margin-top: 61px;
      a {
        font-size: 14px;
        line-height: 19px;
        img {
          margin-left: 13px;
        }

        &:hover, &:active {
          opacity: .7;
        }

        &:last-of-type {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      &:last-of-type {
        margin-top: auto;
        margin-bottom: 20px;
      }
    }
  }
}

@keyframes anim-open {
  from {
    right: -400px;
  }

  to {
    right: 0;
  }
}

@keyframes anim-hidden {
  from {
    right: 0;
  }

  to {
    right: -400px;
  }
}