.Main {
  width: 100%;
  display: flex;

  .main-bg {
    width: 100%;
    height: 959px;
    background: center no-repeat;
  }

  .propositions {
    height: 230px;
    width: 100%;
    background: #6B4FFB;
  }

  .propositions-content {
    margin: 45px 22%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .propositions_item {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 140px;

    .item_text {
      display: flex;
      align-items: center;

      .item_title {
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
      }
      .item_info {
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
      }
    }
    

    &:first-of-type {
      .item_img {
        margin-bottom: 13px;
      }
    }
  }
}

/* Media queries */
@media (max-width: 1280px) {
}

@media (max-width: 992px) {
}

@media (max-width: 768px) {
  .Main {
    .item_info {
      display: none;
    }
  }
}

@media (max-width: 580px) {
  .Main {
    .container {
      max-width: 480px;
      .propositions-content {
        margin: 0 0 45px;
      }
      .propositions_item {
        .item_text {
          .item_title {
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .Main {
    .main-bg {
      height: 480px;
    }
    .container {
      max-width: 340px;
    }
  }
}